import { useAppDispatch, useAppSelector, WizardWrap } from 'app/common';
import { useIntl } from 'app/i18n';
import {
  Audience,
  resetSelectedSending,
  Email,
  selectIsLoadingSendingProfile,
  selectSendingWizardStep,
  SendingWizardStep,
  WizardSetup
} from 'app/pages/my-activities/sending-wizard';
import { PreviewAndSend } from 'app/pages/my-activities/sending-wizard/components/preview/PreviewAndSend';
import { useNavigate } from 'react-router';

export const SendingWizard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const isLoadingSending = useAppSelector<boolean>(selectIsLoadingSendingProfile);
  const currentStep = useAppSelector<SendingWizardStep>(selectSendingWizardStep);

  const handleDismissButtonClick = () => {
    dispatch(resetSelectedSending());
    navigate('/my-activities/e-mailings');
  };

  return (
    <WizardWrap
      isLightDismiss={true}
      isWizardOpened={true}
      onDismissButtonClick={handleDismissButtonClick}
      headerTitle={formatMessage({ id: 'emailing-wizard.header' })}
      classModifier="emailing"
    >
      {!isLoadingSending && (
        <div className="wizard-panel-main">
          {currentStep === SendingWizardStep.Setup && <WizardSetup />}
          {currentStep === SendingWizardStep.Audience && <Audience />}
          {currentStep === SendingWizardStep.Email && <Email />}
          {currentStep === SendingWizardStep.PreviewAndSend && <PreviewAndSend />}
        </div>
      )}
    </WizardWrap>
  );
};
